<template>
  <div v-if="project" class="v-stack h-stretch gap-3">
    <div ref="overlay" class="overlay">
      <div class="card dynamic">
        <div class="header">
          <div>Add Pricelist</div>
        </div>
        <div class="content mt-3">
          <div class="v-stack h-stretch gap-3">
            <label class="text-left">Name</label>
            <input v-model="newPricelistName" type="text" placeholder="name" />
            <label class="text-left">Type</label>
            <select v-model="mode">
              <option value="new">Empty</option>
              <option value="template">From Template</option>
            </select>
            <template v-if="mode == 'new'">
              <label class="text-left">Base Pricelist</label>
              <select v-model="basePricelist">
                <option v-for="pricelist in basePricelists" :key="pricelist._id" :value="pricelist">
                  {{ pricelist.name }}
                </option>
              </select>
            </template>
            <template v-if="mode == 'template'">
              <label class="text-left">Templates</label>
              <select v-model="template">
                <option v-for="pricelist in templatePricelists" :key="pricelist._id" :value="pricelist">
                  {{ pricelist.name }}
                </option>
              </select>
            </template>
          </div>
        </div>
        <div class="footer gap-3 h-space-between mt-3">
          <button @click="dismiss()" class="submit">Cancel</button>
          <button @click="addProjectPricelist()" class="edit">Add</button>
        </div>
      </div>
    </div>
    <div class="h-stack h-space-between">
      <button v-if="!selectMode" class="edit" @click="selectMode = true">
        Select
      </button>
      <button v-if="!selectMode" class="add" @click="addDialog()">Add</button>
      <div v-if="selectMode"></div>
      <button v-if="selectMode" class="add" @click="selectMode = false">
        Done
      </button>
    </div>
    <div class="table">
      <div class="table-header pricelist-row">
        <div class="text-left">Selected</div>
        <div class="text-left">Name</div>
        <div class="text-left">Production Fee</div>
        <div class="text-left">Gear Discount</div>
        <div class="text-left">Budget</div>
        <div class="text-left">Budget VAT</div>
        <div class="text-left">Edited</div>
      </div>
      <div class="table-row pricelist-row" v-for="pricelist in project.pricelists" :key="pricelist._id" v-bind:class="[
        project.selectedPricelists.includes(pricelist._id) ? 'selected' : '',
      ]" @click="handleRowClick(pricelist)">
        <div>
          <i v-if="project.selectedPricelists.includes(pricelist._id)" class="fas fa-check"></i>
        </div>
        <div class="text-left">{{ pricelist.name }}</div>
        <div class="text-left">{{ pricelist.productionFeePercent }} %</div>
        <div class="text-left">{{ pricelist.discountPercent }} %</div>
        <div class="text-left">
          {{ format.currency(pricelist.totalPrice) }} Kč
        </div>
        <div class="text-left">
          {{ format.currency(pricelist.totalPriceWithVat) }} Kč
        </div>
        <div class="text-left">
          {{ formatDate(pricelist.lastEdited, "HH:mm DD.MM.YYYY") }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import utils from "@/utils.js";
import moment from "moment";

export default {
  props: ["id"],
  data() {
    return {
      selectMode: false,
      mode: "new",
      newPricelistName: "",
      template: null,
      templatePricelists: [],
      basePricelist: null,
      basePricelists: [],
      project: null,
      format: utils.format,
    };
  },
  methods: {
    ...mapActions([
      "getProject",
      "addPricelist",
      "getPricelists",
      "editProject",
      "selectProjectPricelist",
    ]),
    handleRowClick(event) {
      if (this.selectMode) {
        this.selectProjectPricelist({
          project: this.project._id,
          pricelist: event._id,
        })
          .then(() => {
            this.refresh();
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        this.$router.push("/pricelist/list/detail/" + event._id);
      }
    },
    addDialog() {
      this.newPricelistName = "Budget";
      this.template = null;
      this.basePricelist =
        this.basePricelists.length > 0 ? this.basePricelists[0] : null;
      this.$refs.overlay.classList.add("show");
    },
    dismiss() {
      this.$refs.overlay.classList.remove("show");
    },
    addProjectPricelist() {
      if (this.mode == "template") {
        if (this.newPricelistName.length > 0 && this.template) {
          this.addPricelist({
            name: this.newPricelistName,
            base: false,
            template: false,
            project: this.id,
            basePricelist: this.template.basePricelist,
            items: this.template.items,
          })
            .then(() => {
              this.refresh();
            })
            .catch((error) => {
              console.log(error);
            });
        }
      } else {
        if (this.newPricelistName.length > 0 && this.basePricelist) {
          this.addPricelist({
            name: this.newPricelistName,
            base: false,
            template: false,
            project: this.id,
            basePricelist: this.basePricelist._id,
          })
            .then(() => {
              this.refresh();
            })
            .catch((error) => {
              console.log(error);
            });
        }
      }

      this.dismiss();
    },
    formatDate(dateString, format) {
      if (dateString) {
        const date = moment(dateString);
        return date.format(format);
      } else {
        return null;
      }
    },
    refresh() {
      this.getPricelists().then((pricelists) => {
        this.basePricelists = pricelists
          .filter((item) => item.base)
          .sort((a, b) => {
            return a.name.localeCompare(b.name, "cs");
          });
        this.templatePricelists = pricelists
          .filter((item) => item.template)
          .sort((a, b) => {
            return a.name.localeCompare(b.name, "cs");
          });
      }).catch((error) => {
        console.log(error)
      });

      this.getProject(this.id)
        .then((project) => {
          this.project = project;

          for (const pricelist of project.pricelists) {
            pricelist.totalPrice = utils.pricelist.totalPrice(pricelist);
            pricelist.totalPriceWithVat =
              utils.pricelist.totalPriceWithVat(pricelist);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  mounted() {
    this.refresh();
  },
};
</script>

<style lang="scss" scoped>
.pricelist-row {
  grid-auto-columns: 60px 1fr 100px 100px 100px 100px 120px !important;
  gap: 10px;
}

.pricelist-row.selected {
  background-color: #ff65b4;
}
</style>
